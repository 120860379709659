export const services = {
  'ai-development': 'Consulting',
  'dedicated-team': 'Dedicated Team',
  'software-product-development': 'Software Product Development',
  'it-staff-augmentation': 'IT Staff Augmentation',
}

const servicesMap = {
  'custom-ai-solutions': 'ai-development',
  'custom-software': 'software-product-development',
  'front-end-development': 'dedicated-team',
  devsecops: 'devops',
  react: 'dedicated-team',
}

export const calendlyLinks = {
  'ai-development': 'https://calendly.com/d/zt2-ykh-gc8/softkraft-consulting-meeting',
  'dedicated-team': 'https://calendly.com/d/zt2-ykh-gc8/softkraft-consulting-meeting',
}

export const getService = () => localStorage && localStorage.service

export const setService = service => {
  if (services[servicesMap[service] || service] && localStorage) {
    localStorage.setItem('service', servicesMap[service] || service)
    return servicesMap[service] || service
  }
}
export const getCalendlyLink = service => (typeof calendlyLinks[service] !== 'undefined' ? calendlyLinks[service] : Object.values(calendlyLinks)[0])
